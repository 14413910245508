<template>
  <b-card>

    <!-- PageHeader -->

    <template>
      <b-row class="mb-md-1">
        <b-col class="mb-1 mb-md-0">
          <slot name="page-title">
            <h2 class="mb-0">{{ $route.meta.title }}</h2>
          </slot>
        </b-col>
        <template v-if="createTitle">
          <b-col
            class="mb-1 mb-md-0 d-flex justify-content-end"
            cols="12"
            md=4
          >
            <slot name="create">
              <common-button
                @click="onCreate"
                v-bind="{
                  block: breakpoint === 'xs' ? true : false
                }"
              >
                <span> {{ createTitle }} </span>
              </common-button>
            </slot>
          </b-col>
        </template>
      </b-row>
    </template>

    <!-- PageHeader -->

    <!-- Search -->

    <template v-if='searchable'>
      <b-row class="mb-1">
        <b-col md="12">
          <slot name="search">
            <common-search
              v-bind="{
                value: search
              }"
              @update="onUpdateSearch"
            />
          </slot>
        </b-col>
      </b-row>
    </template>

    <!-- Search -->

    <!-- Filters -->
    <template v-if="filterable || dateable">
      <b-row>
        <template>
          <b-col class="order-4 order-md-1 order-lg-0">
            <common-button
              v-bind="{
                block: breakpoint === 'xs' ? true : false,
              }"
              @click="onResetFilter"
            >
              <span> Сбросить </span>
            </common-button>
          </b-col>
        </template>

        <!-- Date picker -->

        <template v-if="dateable">
          <template>
            <slot name="date-from">
              <b-col
                v-bind="{
                  md: !initialDate ? '6' : '12'
                }"
                lg="2"
                class="mb-1 mb-lg-0"
              >
                <common-date-picker
                  v-bind="{
                    value: dateFrom ? dateFrom : date_from,
                    useContext: useContext,
                  }"
                  @context="onUpdateDateFrom"
                />
                <!-- <common-date-picker
                  v-bind="{
                    value: dateFrom ? dateFrom : date_from,
                    min: minDate,
                    max: maxDate,
                    useContext: useContext,
                  }"
                  @context="onUpdateDateFrom"
                /> -->
              </b-col>
            </slot>
          </template>

          <template v-if='!(noInitDate || initialDate)'>
            <slot name="date-to">
              <b-col
                lg="2"
                md="6"
                class="mb-1 mb-lg-0"
              >
                <common-date-picker
                  v-bind="{
                    value: date_to,
                  }"
                  @context="onUpdateDateTo"
                />
                <!-- <common-date-picker
                  v-bind="{
                    dateDisabledFn: noInitDate ? null : dateDisabledFnTo,
                    value: date_to,
                    min: minDate,
                    max: maxDate
                  }"
                  @context="onUpdateDateTo"
                /> -->
              </b-col>
            </slot>
          </template>
        </template>

        <!-- Date picker -->

        <template v-if="filterable">
          <slot name="filters"></slot>
        </template>
      </b-row>
    </template>

    <!-- Filters -->

    <template v-if="$slots['actions-left'] || $slots['actions-right']">
      <b-row class="mb-1 mb-md-0">
        <!-- Actions -->
        <b-col class="mb-1 mb-md-0 order-4 order-md-0">
          <div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-start">
            <slot name="actions-left" />
          </div>
        </b-col>
        <b-col
          class="mb-1 mb-md-0"
          md=2
        >
          <div class="d-flex">
            <slot name="actions-right" />
          </div>
        </b-col>
      </b-row>
    </template>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import { AuthNameSpace } from '@/store/modules/auth/types'

export default {
  name: 'TableHeader',
  components: {
    CommonSearch: () => import('@/components/common/common-search'),
    CommonDatePicker: () => import('@/components/common/common-date-picker'),
    CommonButton: () => import('@/components/common/common-button'),
  },
  props: {
    initialDate: {
      type: [Date, String],
      default() {
        return ''
      },
    },
    noInitDate: {
      type: Boolean,
      default() {
        return false
      },
    },
    dateFrom: {
      type: [Date, String],
      default() {
        return ''
      },
    },
    searchable: {
      type: Boolean,
      default() {
        return true
      },
    },
    dateable: {
      type: Boolean,
      default() {
        return true
      },
    },
    filterable: {
      type: Boolean,
      default() {
        return true
      },
    },
    createTitle: {
      type: String,
      default() {
        return ''
      },
    },
    maxDate: {
      type: [Date, String],
      default() {
        return ''
      },
    },
    minDate: {
      type: [Date, String],
      default() {
        return ''
      },
    },
    useContext: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
      default() {
        return 1
      },
    },
  },
  data() {
    return {
      date_from: this.noInitDate ? '' : this.initialDate ? this.initialDate : this.minDate,
      date_to: this.maxDate,
      page: this.currentPage,
      search: '',
      cityLog: '',
    }
  },

  computed: {
    ...mapState(AuthNameSpace, {
      active_city: 'city_id',
    }),
    breakpoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
  },

  methods: {
    onUpdateDateFrom({ selectedDate } = {}) {
      // if (!selectedDate) return
      this.date_from = selectedDate ? selectedDate : this.date_from

      this.transformDates()

      this.$emit('update', { date_from: this.date_from, date_to: this.date_to })
    },

    onUpdateDateTo({ selectedDate } = {}) {
      // if (!selectedDate) return
      this.date_to = selectedDate ? selectedDate : this.date_to

      this.transformDates()

      this.$emit('update', { date_to: this.date_to, date_from: this.date_from })
    },

    transformDates() {
      if (this.date_from) {
        this.date_from.setHours(0)
        this.date_from.setMinutes(0)
        this.date_from.setSeconds(0)
        this.date_from.setMilliseconds(0)
      }

      if (this.date_to) {
        this.date_to.setHours(12)
        this.date_to.setMinutes(0)
        this.date_to.setSeconds(0)
        this.date_to.setMilliseconds(0)
      }
    },

    onUpdateSearch({ search = '' } = {}) {
      if (search.length >= 3 || search.length === 0) {
        this.search = search
        this.$emit('update', { search, page: 1 })
      }
    },

    onResetFilter() {
      this.date_from = this.noInitDate ? '' : this.minDate
      this.date_to = this.maxDate
      this.search = ''
      this.page = 1
      this.$emit('reset', { ...this.$data })
    },

    onCreate() {
      this.$emit('create')
    },

    dateDisabledFnFrom(...props) {
      const currentDateFrom = props[1].getTime()
      const currentDateTo = new Date(this.date_to).getTime()
      const minDate = new Date(this.minDate).setDate(new Date(this.minDate).getDate() - 1)

      return currentDateFrom ? currentDateFrom >= currentDateTo || minDate >= currentDateFrom : false
    },

    dateDisabledFnTo(...props) {
      const currentDateTo = props[1].getTime()
      const currentDateFrom = new Date(this.date_from - 1).getTime()
      const maxDate = new Date(this.maxDate)

      return currentDateTo ? currentDateTo > maxDate || currentDateTo < currentDateFrom : false
    },
  },
  watch: {
    active_city: {
      handler() {
        this.cityLog = this.active_city
      },
    },
    minDate: {
      handler(val, oldVal) {
        if (val !== oldVal && this.cityLog === this.active_city) {
          this.date_from = this.minDate
          this.date_to = this.maxDate
          this.cityLog = ''
        }
      },
    },
  },
}
</script>
