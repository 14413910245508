var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[[_c('b-row',{staticClass:"mb-md-1"},[_c('b-col',{staticClass:"mb-1 mb-md-0"},[_vm._t("page-title",[_c('h2',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$route.meta.title))])])],2),(_vm.createTitle)?[_c('b-col',{staticClass:"mb-1 mb-md-0 d-flex justify-content-end",attrs:{"cols":"12","md":"4"}},[_vm._t("create",[_c('common-button',_vm._b({on:{"click":_vm.onCreate}},'common-button',{
                block: _vm.breakpoint === 'xs' ? true : false
              },false),[_c('span',[_vm._v(" "+_vm._s(_vm.createTitle)+" ")])])])],2)]:_vm._e()],2)],(_vm.searchable)?[_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"md":"12"}},[_vm._t("search",[_c('common-search',_vm._b({on:{"update":_vm.onUpdateSearch}},'common-search',{
              value: _vm.search
            },false))])],2)],1)]:_vm._e(),(_vm.filterable || _vm.dateable)?[_c('b-row',[[_c('b-col',{staticClass:"order-4 order-md-1 order-lg-0"},[_c('common-button',_vm._b({on:{"click":_vm.onResetFilter}},'common-button',{
              block: _vm.breakpoint === 'xs' ? true : false,
            },false),[_c('span',[_vm._v(" Сбросить ")])])],1)],(_vm.dateable)?[[_vm._t("date-from",[_c('b-col',_vm._b({staticClass:"mb-1 mb-lg-0",attrs:{"lg":"2"}},'b-col',{
                md: !_vm.initialDate ? '6' : '12'
              },false),[_c('common-date-picker',_vm._b({on:{"context":_vm.onUpdateDateFrom}},'common-date-picker',{
                  value: _vm.dateFrom ? _vm.dateFrom : _vm.date_from,
                  useContext: _vm.useContext,
                },false))],1)])],(!(_vm.noInitDate || _vm.initialDate))?[_vm._t("date-to",[_c('b-col',{staticClass:"mb-1 mb-lg-0",attrs:{"lg":"2","md":"6"}},[_c('common-date-picker',_vm._b({on:{"context":_vm.onUpdateDateTo}},'common-date-picker',{
                  value: _vm.date_to,
                },false))],1)])]:_vm._e()]:_vm._e(),(_vm.filterable)?[_vm._t("filters")]:_vm._e()],2)]:_vm._e(),(_vm.$slots['actions-left'] || _vm.$slots['actions-right'])?[_c('b-row',{staticClass:"mb-1 mb-md-0"},[_c('b-col',{staticClass:"mb-1 mb-md-0 order-4 order-md-0"},[_c('div',{staticClass:"d-flex flex-column flex-md-row justify-content-center justify-content-md-start"},[_vm._t("actions-left")],2)]),_c('b-col',{staticClass:"mb-1 mb-md-0",attrs:{"md":"2"}},[_c('div',{staticClass:"d-flex"},[_vm._t("actions-right")],2)])],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }